/**
 * 所有图表数据表
 */
const charts = [
  {
    value: 'otherchart',
    label: '特殊图表合集',
    type: 'other',
    show: true
  },
  {
    value: 'radar',
    label: '雷达图',
    type: 'pie',
    show: true
  },
  {
    value: 'mix_line_bar',
    label: '折线图',
    type: 'line',
    show: true
  },
  {
    value: 'mix_timeline_finance',
    label: '柱形|饼图|时间轴',
    type: 'line',
    show: true
  },
  {
    value: 'effectscatter_bmap',
    label: '地理散点图',
    type: 'scatter',
    show: true
  },
  {
    value: 'custom_error_bar',
    label: '误差显示图',
    type: 'bar',
    show: true
  },
  {
    value: 'flow_diagram',
    label: '地理流向图',
    type: 'map',
    show: true
  },
  {
    value: 'pictorialbar',
    label: '象形图',
    type: 'other',
    show: true
  },
  {
    value: 'bar3d',
    label: '三维柱状图',
    type: 'bar',
    show: true
  },
  {
    value: 'treemap_disk',
    label: '简单面积图',
    type: 'other',
    show: true
  },
  {
    value: 'treemap_echarts',
    label: '复杂面积图',
    type: 'other',
    show: true
  },
  {
    value: 'bubble_multi',
    label: '气泡图',
    type: 'other',
    show: true
  },
  {
    value: 'scatter_weight',
    label: '散点图',
    type: 'scatter',
    show: true
  },
  {
    value: 'boxplot_echarts',
    label: '箱须图',
    type: 'other',
    show: true
  },
  {
    value: 'tree_basic',
    label: '树形图',
    type: 'other',
    show: true
  },
  {
    value: 'calendar_echarts',
    label: '日历图',
    type: 'other',
    show: true
  },
  {
    value: 'globe_layers',
    label: '地球仪',
    type: 'map',
    show: true
  },
  {
    value: 'bar_polar',
    label: '极坐标柱形图',
    type: 'bar',
    show: true
  },
  {
    value: 'bar_brush',
    label: '折线|散点|柱形混合图',
    type: 'bar',
    show: true
  },
  {
    value: 'bubble_timeline',
    label: '气泡|时间图',
    type: 'other',
    show: true
  },
  {
    value: 'map_and_bar3d',
    label: '地理柱形(3D)图',
    type: 'map',
    show: true
  },
  {
    value: 'map_and_bar',
    label: '地理柱形(2D)图',
    type: 'map',
    show: true
  },
  {
    value: 'mapchina',
    label: '中国地图',
    type: 'map',
    show: true
  },
  {
    value: 'watermark',
    label: '瀑布图',
    type: 'other',
    show: false
  },
  {
    value: 'dataset_link',
    label: '折线|饼状图',
    type: 'other',
    show: false
  },
  {
    value: 'gauge',
    label: '轮盘图',
    type: 'other',
    show: false
  },
  {
    value: 'bar_animation',
    label: '弹性柱状图',
    type: 'bar',
    show: false
  },
  {
    value: 'map2d_and_bar',
    label: '湖北_柱状图',
    type: 'map',
    show: false
  },
  {
    value: 'arearadar',
    label: '填充雷达图',
    type: 'pie',
    show: false
  },
  {
    value: 'horizontaldoublebar',
    label: '水平双向柱形图',
    type: 'bar',
    show: false
  },
  {
    value: 'pierose',
    label: '饼状图',
    type: 'pie',
    show: true
  },
  {
    value: 'hubei_map',
    label: '湖北地图',
    type: 'map',
    show: true
  },
  {
    value: 'hubei',
    label: '湖北',
    type: 'map',
    show: true
  },
  {
    value: 'table_carousel',
    label: '轮播图',
    type: 'other',
    show: true
  },
  {
    value: 'table_doublecol',
    label: '双列图表',
    type: 'other',
    show: true
  },
  {
    value: 'number_flop',
    label: '数字翻牌器',
    type: 'other',
    show: true
  },
  {
    value: 'first_bar_one',
    label: '柱形图',
    type: 'bar',
    show: false
  },
  {
    value: 'pie_doughnut',
    label: '环形图',
    type: 'pie',
    show: true
  },
  {
    value: 'horizontalbar',
    label: '水平柱形图',
    type: 'bar',
    show: true
  },
  {
    value: 'third_bar_line',
    label: '柱形|折线图',
    type: 'bar',
    show: true
  },
  {
    value: 'digitalflop',
    label: '数字翻牌器',
    type: 'other',
    show: true
  },
  {
    value: 'circlenumber',
    label: '圆环|数字牌',
    type: 'other',
    show: true
  },
  {
    value: 'mapflowline',
    label: '两地流线图',
    type: 'map',
    show: true
  },
  {
    value: 'textbarvertical',
    label: '垂直柱状图',
    type: 'bar',
    show: true
  },
  {
    value: 'doublecolorbar',
    label: '双色柱形图',
    type: 'bar',
    show: true
  },
  {
    value: 'numbertrendicon',
    label: '数字趋势图',
    type: 'other',
    show: true
  },
  {
    value: 'tablestatic',
    label: '普通图表',
    type: 'other',
    show: true
  },
  {
    value: 'tablerimless',
    label: '无边框图表',
    type: 'other',
    show: true
  },
  {
    value: 'progressbar',
    label: '进度条',
    type: 'other',
    show: true
  },
  {
    value: 'ringproportionoftwo',
    label: '环形比例显示器',
    type: 'pie',
    show: true
  },
  {
    value: 'twocolumnbar',
    label: '两列柱形图',
    type: 'bar',
    show: true
  },
  {
    value: 'movetablerimless',
    label: '滚动无边框图表',
    type: 'other',
    show: true
  },
  {
    value: 'multiline',
    label: '折线图',
    type: 'line',
    show: true
  },
  {
    value: 'basefilter',
    label: '筛选器',
    type: 'other',
    show: true
  },
  {
    value: 'world_map_echart',
    label: '各国地图',
    type: 'map',
    show: true
  },
  {
    value: 'movebackcolortable',
    label: '填色轮播图',
    type: 'other',
    show: true
  },
  {
    value: 'timetext',
    label: '时间文本',
    type: 'other',
    show: true
  },
  {
    value: 'piccarousel',
    label: '轮播图(1)',
    type: 'other',
    show: true
  },
  {
    value: 'piccarouselsecond',
    label: '轮播图(2)',
    type: 'other',
    show: true
  },
  {
    value: 'piccarouselthird',
    label: '轮播图(3)',
    type: 'other',
    show: true
  },
  {
    value: 'piccarouselfour',
    label: '轮播图(4)',
    type: 'other',
    show: true
  },
  {
    value: 'piccarouselfive',
    label: '轮播图(5)',
    type: 'other',
    show: true
  },
  {
    value: 'piccarouselsix',
    label: '轮播图(6)',
    type: 'other',
    show: true
  },
  {
    value: 'piccarouselseven',
    label: '轮播图(7)',
    type: 'other',
    show: true
  },
  {
    value: 'piccarouseleight',
    label: '轮播图(8)',
    type: 'other',
    show: true
  },
  {
    value: 'piccarouselnine',
    label: '轮播图(9)',
    type: 'other',
    show: true
  },
  {
    value: 'piccarouselten',
    label: '轮播图(10)',
    type: 'other',
    show: true
  },
  {
    value: 'piccarouselevn',
    label: '轮播图(11)',
    type: 'other',
    show: true
  },
  {
    value: 'pieliquid',
    label: '环形流动图',
    type: 'pie',
    show: true
  },
  {
    value: 'pietypefirst',
    label: '环形图(1)',
    type: 'pie',
    show: true
  },
  {
    value: 'pietypesecond',
    label: '环形图(2)',
    type: 'pie',
    show: true
  },
  {
    value: 'pietypethird',
    label: '环形图(3)',
    type: 'pie',
    show: true
  },
  {
    value: 'barlinepictorialbar',
    label: '象形柱状图',
    type: 'bar',
    show: true
  },
  {
    value: 'barpictorialbarfirst',
    label: '象形图(1)',
    type: 'bar',
    show: true
  },
  {
    value: 'movehovertable',
    label: '高亮轮播图',
    type: 'other',
    show: true
  },
  {
    value: 'first_line_double',
    label: '双折线图(1)',
    type: 'bar',
    show: true
  },
  {
    value: 'firstbaronesecond',
    label: '水平圆角柱状图(2)',
    type: 'bar',
    show: true
  },
  {
    value: 'digitalflopcarousel',
    label: '数字展示牌(2)',
    type: 'other',
    show: true
  },
  {
    value: 'movetablefontstyle',
    label: '填色轮播图(2)',
    type: 'other',
    show: true
  },
  {
    value: 'hubeiline',
    label: '湖北流向图',
    type: 'map',
    show: true
  },
  {
    value: 'mapflowlinetwo',
    label: '全国流向图',
    type: 'map',
    show: true
  },
  {
    value: 'barpictorialbarsecond',
    label: '象形图(2)',
    type: 'bar',
    show: true
  },
  {
    value: 'pietypefour',
    label: '环形图(4)',
    type: 'pie',
    show: true
  },
  {
    value: 'weatherpredict',
    label: '天气预报',
    type: 'other',
    show: true
  },
  {
    value: 'maptooltipmove',
    label: '地图信息轮播',
    type: 'map',
    show: true
  },
  {
    value: 'barsort',
    label: '排序柱状图',
    type: 'bar',
    show: true
  },
  {
    value: 'barstandard',
    label: '柱状图-变异',
    type: 'bar',
    show: true
  },
  {
    value: 'htmlchart1',
    label: 'web合集(1)',
    type: 'other',
    show: true
  },
  {
    value: 'htmlchartset1',
    label: 'web合集(2)',
    type: 'other',
    show: true
  },
  {
    value: 'numflip',
    label: '数字样式集合(1)',
    type: 'other',
    show: true
  }
]
/**
 * 图表类型数据表
 */
const chartTypes = [
  {
    label: '柱状图',
    value: 'bar'
  },
  {
    label: '折线图',
    value: 'line'
  },
  {
    label: '散点图',
    value: 'scatter'
  },
  {
    label: '饼状图',
    value: 'pie'
  },
  {
    label: '地图',
    value: 'map'
  },
  {
    label: '其他',
    value: 'other'
  }
]

/**
 * 图表名字和value的对象
 * [
 *  arearadar: "填充雷达图"
 *  bar3d: "三维柱状图"
 *  bar_animation: "弹性柱状图"
 *   ...
 * ]
 */
const chartChName = {}
charts.forEach(item => {
  chartChName[item.value] = item.label
})

/**
 * 图表的类型列表
 * [{
 *  children: [{…}]
 *   label: "柱状图"
 *   value: "bar"
 * }]
 */
const chartTypeGroup = []
chartTypes.forEach(tItem => {
  let children = []
  children = charts.filter(cItem => {
    return tItem.value === cItem.type
  })
  chartTypeGroup.push({
    ...tItem,
    children
  })
})

// console.log('chartChName, chartTypes', chartChName, chartTypes)

export {
  charts,
  chartTypes,
  chartChName,
  chartTypeGroup
}
