<template>
  <el-dialog
    width="600px"
    :visible.sync="$_visible"
    @open="onOpenDialog"
    @close="closeDialog">
    <p slot="title" class="text_size_16 text_color_white text_weight_bold">图表详情</p>
    <x-text :label-width="160" class="padding-left-20 padding-right-20">
      <x-text-item label="图表名称">
        {{ chartInfo.title }}
      </x-text-item>
      <x-text-item label="创建者">
        {{ chartInfo.createdByUser }}
      </x-text-item>
      <x-text-item label="创建时间">
        {{ chartInfo.created_at }}
      </x-text-item>
      <x-text-item label="最近一次修改者">
        {{ chartInfo.changedByUser }}
      </x-text-item>
      <x-text-item label="最近一次修改时间">
        {{ chartInfo.updated_at }}
      </x-text-item>
      <x-text-item label="图表类型">
        {{ chartInfo.chartType }}
      </x-text-item>
      <x-text-item label="数据源名称">
        {{ chartInfo.dataSourceName }}
      </x-text-item>
      <x-text-item label="数据源类型">
        {{ chartInfo.dataSourceType | typeFormat }}
      </x-text-item>
      <x-text-item label="所属分组">
        {{ chartInfo.groupName }}
      </x-text-item>
      <x-text-item label="描述">
        <p class="textarea">
          {{ chartInfo.description }}
        </p>
      </x-text-item>
    </x-text>
    <div slot="footer">
      <el-button size="small" @click="closeDialog">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    // 图表id
    chartId: {
      type: String,
      default: ''
    },
    // 图表信息
    chartInfo: {
      type: Object,
      default: () => ({
        title: '',
        chartType: '',
        chartName: '',
        createdByUser: '',
        created_at: '',
        changedByUser: '',
        updated_at: '',
        dataSourceType: '',
        dataSourceName: '',
        groupName: '',
        description: ''
      })
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  filters: {
    typeFormat (value) {
      return {
        1: '数据库',
        2: 'CSV',
        3: 'API'
      }[value]
    }
  },
  methods: {
    // 关闭添加表单的dialog
    closeDialog () {
      this.$_visible = false
    },
    // 打开dialog 赋值图表的详情
    onOpenDialog () {
      this.form = this.$clone(this.chartInfo)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
