<!--
    根据原型文档观测
    1、分组来的图表具有
        重命名
        详情
        移动
        复制
        删除
    2、类型来的图表具有
        重命名
        详情
        删除
 -->
<template>
  <div class="chart_list" v-loading="loading" element-loading-background="rgba(24,34,45,0.9)">
    <vue-scroll>
      <div class="header">
         <section-title multiple>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="breadcrumbPath">
              <span>{{ listSource === 'chart-group' ? '图表分组' : '图表类型' }}</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="text_color_active">{{ sourceTitle | formatType(listSource) }}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </section-title>
        <div class="margin-top-20">
          <el-button type="primary" size="small" @click="createChart">新建图表</el-button>
        </div>
        <div class="search_wrapper margin-top-20 flex">
          <div class="margin-right-20">
            <el-input
              size="small"
              clearable
              style="width: 200px;"
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="searchWord"
              @change="onChangeSearchInput">
            </el-input>
          </div>
          <el-popover
            trigger="hover"
            placement="bottom"
            :visible-arrow="false"
            popper-class="my_popover">
            <div class="my_popover_list">
              <div
                :class="['my_popover_list_item', { 'select': curOrder === item.value }]"
                v-for="item in orderList"
                :key="item.value"
                @click="changeOrder(item.value, item.orderBy)">
                <p>{{ item.label }}</p>
              </div>
            </div>
            <p slot="reference" class="text_size_14 text_color_white text_line_height_32 cursor-pointer">
              <span>{{ curOrder | filterOrderValue(orderList) }}</span>
              <i class="el-icon-caret-bottom" style="margin-left: 8px;"></i>
            </p>
          </el-popover>
        </div>
      </div>
      <div class="chart_list_wrapper">
        <div
          v-for="chartItem in chartList"
          :key="chartItem.id"
          :class="['chart_item', { 'active' : curPopoverId === chartItem.id }]"
          :style="{
            backgroundImage: `url(${API_BASE_URL + chartItem.coverUrl})`
          }">
          <div class="chart_item_top" @click="editChart(chartItem.id)"></div>
          <div class="chart_item_footer">
            <div class="flex justify_between align_center text_line_height_30">
              <p class="title text_size_14 text_color_white text_elip" :title="chartItem.title">{{ chartItem.title }}</p>
              <el-popover
                @show="curPopoverId = chartItem.id"
                @hide="curPopoverId = ''"
                trigger="click"
                placement="bottom"
                :visible-arrow="false"
                popper-class="my_popover">
                <div class="my_popover_list">
                  <div class="my_popover_list_item" @click="openDetailDialog(chartItem)">
                    <i class="iconfont iconxiangqing"></i>
                    <p>查看详情</p>
                  </div>
                  <div class="my_popover_list_item" @click="openRenameDialog(chartItem)">
                    <i class="iconfont iconbianji"></i>
                    <p>重命名</p>
                  </div>
                  <template v-if="listSource === 'chart-group'">
                    <div class="my_popover_list_item" @click="openCopyChart(chartItem)">
                      <i class="iconfont iconfuzhi"></i>
                      <p>复制图表</p>
                    </div>
                    <div class="my_popover_list_item" @click="openMoveDialog(chartItem)">
                      <i class="iconfont iconyidong"></i>
                      <p>移动图表</p>
                    </div>
                  </template>
                  <div class="my_popover_list_item" @click="deleteChartBefore(chartItem)">
                    <i class="iconfont iconshanchu"></i>
                    <p>删除图表</p>
                  </div>
                </div>
                <div slot="reference" class="icon_wrapper">
                  <i class="iconfont icongongneng text_color_black_10"></i>
                </div>
              </el-popover>
            </div>
            <div class="flex justify_between text_size_12 text_color_secondary text_line_height_30">
              <p>{{ chartItem.changedByUser }}</p>
              <p>{{ chartItem.updated_at }}</p>
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>

    <!-- 图表详情 -->
    <detail-chart
      :visible.sync="detailChartDialogVisible"
      :chart-id="curChartId"
      :chart-info="curChart">
    </detail-chart>

    <!-- 修改图表名称 -->
    <rename-chart
      :visible.sync="renameChartDialogVisible"
      :chart-id="curChartId"
      :group-id="groupId"
      :old-name="curRenameChartName"
      @callback="getChartList">
    </rename-chart>

    <!-- 复制图表到新的项目和分组 -->
    <copy-chart
      :visible.sync="copyChartDialogVisible"
      :chart-id="curChartId"
      @callback="getChartList">
    </copy-chart>

    <!-- 移动图表到新的分组 -->
    <move-chart
      :visible.sync="moveChartDialogVisible"
      :chart-id="curChartId"
      @callback="getChartList">
    </move-chart>

  </div>
</template>

<script>
import { chartTypes } from '../../../chart-detail/libs/chart-database'
import { API_BASE_URL } from '~/config/index'
import * as api from '~/api'
import renameChart from './components/rename-chart'
import moveChart from './components/move-chart'
import copyChart from './components/copy-chart'
import detailChart from './components/detail-chart'
export default {
  data () {
    return {
      API_BASE_URL: API_BASE_URL,
      loading: false,
      // 修改图表名称的dialog
      renameChartDialogVisible: false,
      // 移动图表Dialog
      moveChartDialogVisible: false,
      // 复制图表Dialog
      copyChartDialogVisible: false,
      // 删除图表Dialog
      detailChartDialogVisible: false,
      // 当前所选择的图表id
      curChartId: '',
      // 当前所要查看的图表详情
      curChart: {},
      // 当前所选择要修改名称的图表名称
      curRenameChartName: '',
      // 当前所选的item的popover
      curPopoverId: '',
      // 当前所选排序规则 默认按 创建时间排序
      curOrder: 'updated_at',
      curOrderBy: 'desc',
      // 搜索关键词
      searchWord: '',
      // 排序规则列表
      orderList: [
        {
          label: '按创建时间',
          value: 'created_at',
          orderBy: 'desc'
        },
        {
          label: '按修改时间排序',
          value: 'updated_at',
          orderBy: 'desc'
        },
        {
          label: '按名称排序',
          value: 'title',
          orderBy: 'asc'
        }
      ],
      // 图表列表
      chartList: []
    }
  },
  computed: {
    // 列表的来源（chart-group: 图表分组，type-group: 类型分组）
    listSource () {
      return this.$route.query.listSource || ''
    },
    // 来源标题
    sourceTitle () {
      return decodeURIComponent(this.$route.query.sourceTitle) || ''
    },
    // 项目id
    projectId () {
      return this.$route.query.projectId || ''
    },
    // 项目标题
    projectName () {
      return decodeURIComponent(this.$route.query.projectName) || ''
    },
    // 分组id
    groupId () {
      return this.$route.query.groupId || ''
    },
    groupType () {
      return this.$route.query.groupType || ''
    },
    // 返回上一层的地址
    breadcrumbPath () {
      let path = ''
      if (this.listSource === 'chart-group') {
        path = '/project-detail/chart-group'
      } else {
        path = '/project-detail/type-group'
      }

      return {
        path,
        query: {
          projectId: this.projectId,
          projectName: encodeURIComponent(this.projectName)
        }
      }
    }
  },
  filters: {
    // 格式化排序规则
    filterOrderValue (value, list) {
      const obj = list.find(item => {
        return item.value === value
      })
      return obj ? obj.label : ''
    },
    formatType (value, listSource) {
      let result = ''
      if (listSource === 'type-group') {
        const element = chartTypes.find(element => {
          return element.value === value
        })
        result = element ? element.label : ''
      } else {
        result = value
      }
      return result
    }
  },
  components: {
    renameChart,
    moveChart,
    copyChart,
    detailChart
  },
  created () {
    // 获取图表列表
    this.getChartList()
  },
  methods: {
    // 获取图表列表
    getChartList () {
      this.loading = true
      api.getChartList({
        chartGroupId: this.groupId,
        chartGroupType: this.groupType,
        spaceId: this.projectId,
        keyWord: this.searchWord,
        orderWord: this.curOrder,
        orderType: this.orderBy
      }).then(res => {
        if (res.data.code === 0) {
          // console.log('图表列表', res.data.data)
          this.chartList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },
    // 改变排序规则
    changeOrder (value, orderBy) {
      this.curOrder = value
      this.curOrderBy = orderBy
      // 重新获取
      this.getChartList()
    },
    // 搜索框值发生变化
    onChangeSearchInput (value) {
      this.getChartList()
    },

    // 点击新建图表
    createChart () {
      this.$router.push({
        path: '/chart-detail',
        query: {
          mode: 'create',
          projectId: this.projectId,
          projectName: encodeURIComponent(this.projectName),
          sidebar: this.listSource,
          groupId: this.groupId,
          groupTitle: encodeURIComponent(this.sourceTitle),
          groupType: this.groupType
        }
      })
    },
    editChart (id) {
      this.$router.push({
        path: '/chart-detail',
        query: {
          mode: 'edit',
          projectId: this.projectId,
          projectName: encodeURIComponent(this.projectName),
          chartId: id,
          sidebar: this.listSource,
          groupId: this.groupId,
          groupTitle: encodeURIComponent(this.sourceTitle),
          groupType: this.groupType
        }
      })
    },
    // 打开图表详情的弹窗
    openDetailDialog (item) {
      this.curChartId = item.id
      this.curChart = { ...item }
      this.detailChartDialogVisible = true
    },
    // 打开重命名图表的弹窗
    openRenameDialog (item) {
      this.curChartId = item.id
      this.curRenameChartName = item.title
      this.renameChartDialogVisible = true
    },
    // 移动图表到新的分组
    openMoveDialog (item) {
      this.curChartId = item.id
      this.moveChartDialogVisible = true
    },
    // 复制图表到新的分组
    openCopyChart (item) {
      this.curChartId = item.id
      this.copyChartDialogVisible = true
    },
    // 删除图表前确定
    deleteChartBefore (item) {
      this.$msgbox({
        title: '删除图表',
        message: '确定要删除该图表？图表删除后无法找回！',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.deleteChart(item, instance, done)
          } else {
            done()
          }
        }
      }).then(action => {
        console.log('已完成')
      })
    },
    // 调用删除图表接口
    deleteChart (item, instance, done) {
      instance.confirmButtonLoading = true
      api.deleteChart({
        chartEntityId: item.id
      }).then(res => {
        if (res.data.code === 0) {
          // console.log('删除图表', res.data.data)
          this.$message.success('删除成功！')

          // 重新调用 获取图表列表
          this.getChartList()
        } else {
          this.$message.error(res.data.message)
        }
        done()
        instance.confirmButtonLoading = false
      }).catch(err => {
        instance.confirmButtonLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart_list {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0px 30px 0 30px;
    .header {
      display: flex;
      flex-direction: column;
      padding: 30px 10px 0 10px;
      margin-bottom: 20px;
      .search_wrapper {
        height: 32px;
        .popover_wrapper {
          width: 150px;
        }
      }
    }
    .chart_list_wrapper {
      display: flex;
      flex-wrap: wrap;
      .chart_item {
        display: flex;
        flex-direction: column;
        width:270px;
        height:200px;
        border:1px solid #606A75;
        border-radius:4px;
        background-size: cover;
        margin: 0 10px 20px 10px;
        cursor: pointer;
        .chart_item_top {
          flex: 1;
          border-radius: 4px 4px 0px 0px;
          transition: all 0.3s ease;
        }
        .chart_item_footer {
          flex: none;
          width: 100%;
          height: 79px;
          background:rgba(3,13,23,0.5);
          padding: 10px 20px;
          border-radius:0px 0px 4px 4px;
          .title {
            max-width: 200px;
            transition: all 0.3s ease;
          }
          .icon_wrapper {
            display: none;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            border-radius:2px;
            &:hover {
              background-color: #424C57;
            }
          }
        }
        &:hover {
          .chart_item_top {
            background-color: rgba(66,76,87,0.8);
          }
          .chart_item_footer {
            .title {
              color: #6AEBFF;
            }
            .icon_wrapper {
              display: block;
            }
          }
        }
        &.active {
          .chart_item_top {
            background-color: rgba(66,76,87,0.8);
          }
          .chart_item_footer {
            .title {
              color: #6AEBFF;
            }
            .icon_wrapper {
              display: block;
              background-color: #424C57;
            }
          }
        }
      }
    }
  }
</style>
