import { render, staticRenderFns } from "./rename-chart.vue?vue&type=template&id=188590ba&scoped=true&"
import script from "./rename-chart.vue?vue&type=script&lang=js&"
export * from "./rename-chart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "188590ba",
  null
  
)

export default component.exports