<template>
  <el-dialog
    width="600px"
    :visible.sync="$_visible"
    @close="closeDialog">
    <p slot="title" class="text_size_16 text_color_white text_weight_bold">移动图表</p>
    <el-form
      class="form padding-left-20 padding-right-20"
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-position="left"
      label-width="160px"
      hide-required-asterisk>
      <el-form-item label="移动图表至" prop="chartGroupId">
        <el-select style="width: 360px;" v-model="form.chartGroupId" placeholder="请选择分组">
          <el-option
            v-for="item in options"
            :key="item.groupId"
            :label="item.groupName"
            :value="item.groupId">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button size="small" @click="closeDialog">取消</el-button>
      <el-button type="primary" :loading="saveBtnLoading" size="small" @click="saveForm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import * as api from '~/api'
export default {
  data () {
    return {
      // 确定按钮loading
      saveBtnLoading: false,
      // 分组选项
      options: [],
      // 表单对象
      form: {
        chartGroupId: ''
      },
      // 添加目标表达那对象验证
      rules: {
        chartGroupId: [
          { required: true, message: '请输入选择新的分组', trigger: 'change' }
        ]
      }
    }
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    // 图表id
    chartId: {
      type: String,
      default: ''
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    // 项目id
    projectId () {
      return this.$route.query.projectId || ''
    }
  },
  created () {
    // 获取分组列表
    this.getChartGroupList()
  },
  methods: {
    // 获取指定项目下的图表分组
    getChartGroupList () {
      api.getChartGroupList({
        projectSpaceId: this.projectId
      }).then(res => {
        if (res.data.code === 0) {
          console.log('图表分组列表', res.data.data)
          this.options = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 移动图表
    moveChart (result) {
      this.saveBtnLoading = true
      api.moveChart({
        // 图表id
        chartEntityId: this.chartId,
        // 所移动的目标分组id
        chartGroupId: this.form.chartGroupId
      }).then(res => {
        if (res.data.code === 0) {
          console.log('移动图表成功', res.data.data)
          this.$message.success('移动图表成功')

          this.$emit('callback')
          this.closeDialog()
        } else {
          this.$message.error(res.data.message)
        }
        this.saveBtnLoading = false
      }).catch(err => {
        this.saveBtnLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 保存提交
    saveForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 移动图表
          this.moveChart()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    // 关闭添加表单的dialog
    closeDialog () {
      this.$refs.form.resetFields()
      this.$_visible = false
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
