<template>
  <el-dialog
    width="600px"
    :visible.sync="$_visible"
    @close="closeDialog">
    <p slot="title" class="text_size_16 text_color_white text_weight_bold">复制图表</p>
    <p class="text_size_14 text_color_white text_line_height_20 margin-bottom-20 margin-left-20">将给图表另存为</p>
    <el-form
      class="form padding-left-20 padding-right-20"
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-position="left"
      label-width="160px"
      hide-required-asterisk>
      <el-form-item label="图表名称" prop="newTitle">
        <el-input type="text" v-model="form.newTitle" placeholder="请输入图表名称"></el-input>
      </el-form-item>
      <el-form-item label="选择项目" prop="projectId">
        <el-select style="width: 360px;" v-model="form.projectId" @change="onChangeProjectId" placeholder="请选择项目">
          <el-option
            v-for="item in projectList"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择分组" prop="chartGroupId">
        <el-select style="width: 360px;" v-model="form.chartGroupId" placeholder="请选择分组">
          <el-option
            v-for="item in groupList"
            :key="item.groupId"
            :label="item.groupName"
            :value="item.groupId">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button size="small" @click="closeDialog">取消</el-button>
      <el-button type="primary" :loading="saveBtnLoading" size="small" @click="saveForm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import * as api from '~/api'
export default {
  data () {
    return {
      // 确定按钮loading
      saveBtnLoading: false,
      // 分组选项选择器Loading
      groupSelectLoading: false,
      // 项目列表
      projectList: [],
      // 分组选项
      groupList: [],
      // 表单对象
      form: {
        // 新的名称
        newTitle: '',
        // 项目id
        projectId: '',
        // 目标分组
        chartGroupId: ''
      },
      // 添加目标表达那对象验证
      rules: {
        newTitle: [
          { required: true, message: '请输入图表名称', trigger: 'blur' }
        ],
        projectId: [
          { required: true, message: '请选择项目', trigger: 'change' }
        ],
        chartGroupId: [
          { required: true, message: '请选择分组', trigger: 'change' }
        ]
      }
    }
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    // 图表id
    chartId: {
      type: String,
      default: ''
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  created () {
    // 获取项目及个人空间列表
    this.getProjectSpaceList()
  },
  methods: {
    // 获取项目及个人空间列表
    getProjectSpaceList (result) {
      api.getProjectSpaceList().then(res => {
        if (res.data.code === 0) {
          // console.log('项目列表', res.data.data)
          this.projectList = res.data.data.project_spaces
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },
    // 项目发生改变 获取项目下的分组
    onChangeProjectId () {
      // 重置选择的目标分组id
      this.chartGroupId = ''
      this.getChartGroupList()
    },
    // 获取指定项目下的图表分组
    getChartGroupList () {
      this.groupSelectLoading = true
      api.getChartGroupList({
        projectSpaceId: this.form.projectId
      }).then(res => {
        if (res.data.code === 0) {
          // console.log('图表分组列表', res.data.data)
          this.groupList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
        this.groupSelectLoading = false
      }).catch(err => {
        this.groupSelectLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 复制图表
    copyChart (result) {
      this.saveBtnLoading = true
      api.copyChart({
        chartEntityId: this.chartId,
        ...this.form
      }).then(res => {
        if (res.data.code === 0) {
          console.log('复制图表成功', res.data.data)
          this.$message.success('复制图表成功')

          this.$emit('callback')
          this.closeDialog()
        } else {
          this.$message.error(res.data.message)
        }
        this.saveBtnLoading = false
      }).catch(err => {
        this.saveBtnLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 保存提交
    saveForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 复制图表
          this.copyChart()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    // 关闭添加表单的dialog
    closeDialog () {
      this.$refs.form.resetFields()
      this.$_visible = false
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
